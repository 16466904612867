body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

canvas {
  display: block;
}

/*# sourceMappingURL=index.1feeff5a.css.map */
